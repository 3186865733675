// Colors
$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #FF8300;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;
$brown:   #AC872A;

$primary:       $orange;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

// Fonts
$font-family-sans-serif:      "Roboto", sans-serif;
$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.8;
$h3-font-size:                $font-size-base * 1.6;
$h4-font-size:                $font-size-base * 1.4;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

// Breadcrumbs
$breadcrumb-font-size:              $font-size-sm;
$breadcrumb-bg:                     $gray-100;
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;
$breadcrumb-divider:                quote(">");
$breadcrumb-border-radius:          $border-radius;

// Offcanvas
$zindex-offcanvas:                      1070;
$zindex-offcanvas-backdrop:             1060;

$offcanvas-heading-bg:                  $orange;
$offcanvas-heading-color:               $white;
$offcanvas-bg:                          $white;
$offcanvas-backdrop-bg:                 $black;
$offcanvas-backdrop-opacity:            .5;

$offcanvas-max-width:                   25rem;
$offcanvas-font-size:                   $h5-font-size;

$offcanvas-fade-transform:              translateX(-100%);
$offcanvas-show-transform:              none;
$offcanvas-transition:                  transform .2s ease-out;
