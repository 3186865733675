body {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-light;
}
img {
    max-width: 100%;
    height: auto;
}
a {
    color: $brown;

    &:hover {
        color: darken($brown, 5);
    }
}

h1, .h1 { @include font-size($h1-font-size); }
h2, .h2 { @include font-size($h2-font-size); }
h3, .h3 { @include font-size($h3-font-size); }
h4, .h4 { @include font-size($h4-font-size); }
h5, .h5 { @include font-size($h5-font-size); }
h6, .h6 { @include font-size($h6-font-size); }

.btn, .form-control {
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($primary, .25) !important;
    }
}
