.navbar {
    background: $primary;
    font-weight: $font-weight-bold;

    @include media-breakpoint-down(sm) {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-toggler-title {
        font-size: $font-size-sm;
        color: $gray-900;
    }
    .navbar-brand {
        padding: 0;
        margin-top: 5px;
        margin-bottom: -18px;

        img {
            max-width: 100px;
        }
        @include media-breakpoint-up(md) {
            margin-right: 5rem;
        }
        @include media-breakpoint-down(sm) {
            padding: 0;
            margin-top: 5px;
            margin-bottom: -7px;

            img {
                max-width: 60px;
            }
        }
    }    
    .dropdown {
        @include media-breakpoint-down(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .nav-link {
                //width: 100%;
            }
            .dropdown-menu {
                flex: 0 0 100%;
            }
        }
        @include media-breakpoint-up(md) {
            &:hover {
                .dropdown-menu {
                    margin-top: 0;
                    display: block;
                }
            }
        }
        &.show {
            .dropdown-item-toggle {
                &:before {
                    content: '\f068';
                }
            }
        }
        .dropdown-item-toggle {
            display: none;
            @extend .btn;

            &:before {
                font-family: 'FontAwesome';
                content: '\f067';
            }
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }
    .navbar-nav {
        .nav-link {
            padding: 0.5rem 1rem;
        }
        .nav-item {
            border-bottom: 3px solid transparent;

            &.active {
                border-bottom: 3px solid $primary;
            }
        }
    }
}
