.slick-slider {
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @extend .btn;
        background: rgba($primary, 0.5);
        color: $white;
        z-index: 10;

        &:hover {
            background: rgba($primary, 0.75);
            color: $white;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
}
