.content {
    padding: 3rem 0;
}
.blocks {
    margin-bottom: 3rem;

    &[class*="bg-"] {
        .block {
            .block-content {
                background: $white;
            }
        }
    }
    .block {
        display: block;
        margin-bottom: 2rem;
        text-decoration: none;
        
        &.past {
            text-decoration: line-through;
            opacity: 0.45;
        }
        .block-image {
            border: 2px solid $gray-800;
            @include border-top-right-radius(15px);
            overflow: hidden;
        }
        .block-content {
            display: flex;
            align-items: stretch;
            background: $gray-100;
        }
        .heading {
            padding: 0.5rem 1rem;

            .title {
                color: $gray-900;
                font-weight: $font-weight-base;
            }
            .location {
                color: $gray-500;
                font-size: $font-size-sm;

                &:before {
                    margin-right: 0.5rem;
                    font-family: 'FontAwesome';
                    content: '\f041';
                }
            }
        }
        .date-wrapper {
            display: flex;
            align-items: center;
            padding: 0.6rem 1.25rem 0.5rem 1.25rem;
            background: $primary;
            color: $white;
            line-height: 1.2;

            .month {
                display: block;
            }
            .day {
                display: block;
                font-size: $h4-font-size;
                font-weight: $font-weight-base;
            }
        }
    }
}
.partners {
    padding: 3rem 0;
    text-align: center;
    background: $gray-100;
}
.reviews {
    padding: 3rem 0;
    text-align: center;

    .item {
        padding: 1rem 2rem;
        text-align: center;
        .title {
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
        }
    }
    .slick-slide {
        opacity: 0.3;
    }
    .slick-center {
        opacity: 1;
    }
}
