.footer {
    padding: 4rem 0 3rem;
    background: $gray-800;
    color: $gray-100;

    a {
        color: $white;

        &:hover {
            color: $orange;
        }
    }
    .footer-block {
        margin-bottom: 2rem;

        .block-title {
            @include heading($h5-font-size);
            letter-spacing: 1px;
        }
        .block-content {
            ul {
                padding-left: 0;
                list-style-type: none;

                li {
                    + li {
                        margin-top: 0.25rem;
                    }
                }
            }
        }
    }
}
.footer-bottom {
    padding: 1rem 0;
    background: $gray-900;
    color: $gray-500;
    font-size: $font-size-sm;
}
