.breadcrump-wrapper {
    margin-top: 2rem;
}
.breadcrumb {
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  @include font-size($breadcrumb-font-size);
  background-color: $breadcrumb-bg;
  @include border-radius($breadcrumb-border-radius);
}
.breadcrumb-item {
  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding;

    &::before {
      padding-right: $breadcrumb-item-padding;
      color: $breadcrumb-divider-color;
      content: escape-svg($breadcrumb-divider);
    }
  }
}
